require('./bootstrap');

class Screen {
  constructor(id) {
    this.totalLines = 0;
    this.maxLines = 14;
    this.domElement = $(id);
    this.lineCount = 0;
  }

  addLine(line) {
    this.totalLines++;

    if (this.lineCount === this.maxLines){
      this.domElement.find('#line-' + (this.totalLines - this.maxLines)).remove();
    } else {
      this.lineCount++;
    }

    this.domElement.append('<p id="line-' + this.totalLines + '">' + line + '</p>');
    return '#line-' + this.lineCount;
  }

  clearScreen() {
    this.domElement.html('');
    this.lineCount = 0;
  }
}

let dotsCallback = (endingText) => {
  return (config, screen) => {
    let lineID = screen.addLine(config.msg);
    let dots = ".";
    let incrementor = window.setInterval(() => {
      $(lineID).html(config.msg + " " + dots);
      if (dots.length >= 3){
        clearInterval(incrementor);
        $(lineID).html(config.msg + dots + endingText).find('strong').text('[OK]').addClass('selected');
      }
      dots += ".";
    }, 200);
  }
};

let bootupSequence = [
  {
    "msg": "Ore Bios (C) 2086 Mining Corp, Ltd.",
    "delay": 500,
  },
  {
    "msg": "BIOS Date 01/01/2086 16:13:29 Ver: 11.00.09",
    "delay": 0,
  },
  {
    "msg": "CPU: PPC(R) CPU RedCore @ 40Mhz",
    "delay": 500,
  },
  {
    "msg": "<strong class=\"selected\">Memory Test:</strong>",
    "delay": (128 * 10),
    "callback": (config, screen) => {
      let lineID = screen.addLine(config.msg);
      let counter = 0;

      let incrementor = window.setInterval(() => {
        $(lineID).html(config.msg + " " + counter + "K")
        counter++;
        if (counter === 128) {
          clearInterval(incrementor)
          $(lineID).html(config.msg + " " + counter + "K OK")
        }
      }, 10);
    }
  },
  {},
  {
    "delay": 300,
    "msg": "Booting from Hard Disk..."
  },
  {
    "delay": 300,
    "msg": "Starting ColonyOS v1.03",
  },
  {
    "delay": (200*3),
    "msg": "<strong>[...]</strong> Waiting for /dev to be fully populated",
    "callback": dotsCallback("done")
  },
  {
    "delay": (200*3),
    "msg": "<strong>[...]</strong> Detecting Network",
    "callback": dotsCallback("found")
  },
  {
    "delay": 300,
    "msg": "<strong class=\"selected\">[OK]</strong> Identifying Peripheral devices...done."
  },
  {
    "delay": 30,
    "msg": "<strong class=\"selected\">[OK]</strong> Harmonising Frequencies."
  },
  {
    "delay": 30,
    "msg": "<strong class=\"selected\">[OK]</strong> Identifying Lama Farmers"
  },
  {
    "delay": 30,
    "msg": "<strong class=\"selected\">[OK]</strong> Injecting Magic smoke...done."
  },
  {},
  {
    "delay": 300,
    "msg": "Mainframe Uplink Configured"
  },
  {},
  {
    "msg": "Starting Desktop...<span class=\"carat\"></span>"
  },
  {
    "callback": (config, screen) => {
      screen.clearScreen();
    }
  },
  {
    "msg": "<h1 style='text-align: center; margin-top: 24%;'>Moon Miner... Coming Soon.</h1>"
  }
];

let defaultSequence = {
  "msg": "&nbsp;",
  "delay": 1000,
  "callback": (config, screen) => {
    screen.addLine(config.msg);
  },
};

$(document).ready(function () {
  let screen = $('#screen01');
  let delay = 0;

  let BootScreen = new Screen('#screen01');

  bootupSequence.forEach(function (e) {
    let config = {
      "msg": e.msg || defaultSequence.msg,
      "delay": e.delay || defaultSequence.delay,
      "callback": e.callback || defaultSequence.callback,
    };

    window.setTimeout(config.callback, delay, config, BootScreen);
    delay += config.delay
  });
});
